
import { defineComponent, setTransitionHooks } from "vue";
import axios from "axios";
import ItemCounter from "@/components/ItemCounter.vue"; // @ is an alias to /src
import InterestingItem from "@/components/InterestingItem.vue";
import Navbar from "@/components/Navbar.vue";
import AddAmount from "@/components/AddAmount.vue";
import NewEntry from "@/components/NewEntry.vue";

export default defineComponent({
  name: "MainView",
  components: {
    ItemCounter,
    InterestingItem,
    NewEntry,
    Navbar,
    AddAmount,
  },
  data() {
    return {
      name: "Litter Tracker",
      reset: false,
      error: false,
      data: {},
      interesting: [] as Array<string>,
      lastData: {},
      lastInteresting: [] as Array<string>,
      session: "",
      reg: false,
      loading: false,
      customAmountActive: false,
      customAmountName: "",
      categories: [
        "Cigarette Butts",
        "Food Wrappers",
        "Cups & Lids",
        "Bottles & Cans",
        "Paper & Cardboard",
        "Plastic Bags",
        "Styrofoam",
        "Glass",
        "Clothing/Shoes",
        "Metal",
        "Electronics",
        "Other",
      ],
    };
  },
  methods: {
    checkSessionCookie() {
      let cookieValues = decodeURIComponent(document.cookie).split(";");

      for (let n = 0; n < cookieValues.length; n++) {
        let x = cookieValues[n].split("=");
        if (x[0].trim() === "session") {
          return x[1];
        }
      }
      return "";
    },
    registerSession(uuid) {
      this.session = uuid;
      this.reg = false;
    },
    initCount(name) {
      if (!this.data[name]) {
        this.data[name] = 0;
      }

      this.setCookie(name);
    },
    incrementCount(name) {
      this.data[name]++;

      this.setCookie(name);
    },
    decrementCount(name) {
      if (this.data[name] > 0) {
        this.data[name]--;
        this.setCookie(name);
      }
    },
    customValuePrompt(name) {
      this.customAmountName = name;
      this.customAmountActive = true;
    },
    customValueAdd(amount) {
      console.log(this.customAmountName + " " + amount);
      this.data[this.customAmountName] += amount;
      if (this.data[this.customAmountName] < 0) {
        this.data[this.customAmountName] = 0;
      }
      this.setCookie(this.customAmountName);

      this.customAmountActive = false;
    },
    setCookie(item: string, interesting: boolean) {
      let d = new Date();
      d.setTime(d.getTime() + 86400000);

      if (interesting) {
        document.cookie = `interesting_${item.replace(
          /[^\w]/g,
          ""
        )}=${item}; SameSite=Strict; Expires=${d.toUTCString()};`;
      } else {
        document.cookie = `item_${item}=${
          this.data[item]
        }; SameSite=Strict; expires=${d.toUTCString()};`;
      }
    },
    loadCookies() {
      let cookieValues = decodeURIComponent(document.cookie).split(";");

      this.interesting = [];

      for (let n = 0; n < cookieValues.length; n++) {
        let x = cookieValues[n].split("=");
        if (x[0].trim().includes("item_")) {
          this.data[x[0].split("item_")[1]] = parseInt(x[1]);
        }
        if (x[0].trim().includes("interesting_")) {
          this.interesting.push(x[1]);
        }
      }
    },
    uploadData() {
      console.log("uploading data");

      const promise = new Promise((resolve, reject) => {
        if (this.session) {
          this.loading = true;

          let p = [];

          if (JSON.stringify(this.data) !== JSON.stringify(this.lastData)) {
            console.log("Send items");
            p.push(
              axios({
                method: "post",
                url: `https://api.littertracker.greenteamscanada.ca/api/${this.session}/log`,
                data: this.data,
              })
            );
            this.lastData = JSON.parse(JSON.stringify(this.data));
          }

          if (
            JSON.stringify(this.interesting) !==
            JSON.stringify(this.lastInteresting)
          ) {
            console.log("Send interesting");
            p.push(
              axios({
                method: "post",
                url: `https://api.littertracker.greenteamscanada.ca/api/${this.session}/interesting`,
                data: this.interesting,
              })
            );
            this.lastInteresting = JSON.parse(JSON.stringify(this.interesting));
          }

          Promise.all(p)
            .catch((e) => {
              console.error(e);
              this.error = true;
              reject(0);
            })
            .finally(() => {
              setTimeout(() => {
                this.loading = false;
              }, 1000);

              resolve(1);
            });
        }
      });
      return promise;
    },
    clearData() {
      this.uploadData().then((r) => {
        let cookieValues = decodeURIComponent(document.cookie).split(";");

        for (let n = 0; n < cookieValues.length; n++) {
          let x = cookieValues[n].split("=");

          document.cookie =
            x[0].trim() +
            "=0;SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:00 GMT;";
          location.reload();
        }

        this.reset = false;
      });
    },
    addInteresting(item: string) {
      this.interesting.push(item);
      this.setCookie(item, true);
    },
    removeInteresting(index: number) {
      document.cookie = `interesting_${this.interesting[index].replace(
        /[^\w]/g,
        ""
      )}=; SameSite=Strict; Expires=Thu, 01 Jan 1970 00:00:00 GMT`;

      let left = this.interesting.slice(0, index);
      let right = this.interesting.slice(index + 1);

      this.interesting = left.concat(right);
    },
  },
  mounted() {
    document.title = "Litter Tracker";

    // this.categories.forEach((element) => {
    //   this.data[element] = 0;
    // });

    this.session = this.checkSessionCookie();

    if (!this.session) {
      console.log("No session found. Generating session.");
      this.reg = true;
    } else {
      this.loadCookies();

      //pull session data from DB
      //compare data with cookies
      //if data does not match
      //check if cookies 0, if so go with db otherwise use cookies
    }

    this.uploadData();
    let uploadInterval = setInterval(() => {
      this.uploadData().then(() => {
        console.log("upload complete");
      });
    }, 30000);
  },
});
