import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container pe-0" }
const _hoisted_2 = { class: "row align-items-center" }
const _hoisted_3 = { class: "col-4 ps-1 pe-0" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "col p-0 text-nowrap" }
const _hoisted_7 = ["id", "data-bs-title"]
const _hoisted_8 = { class: "col-2 p-0" }
const _hoisted_9 = { class: "badge text-bg-success" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-success btn-lg m-1 p-1",
          style: {"min-width":"2em"},
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitIncrement && _ctx.emitIncrement(...args))),
          disabled: _ctx.error
        }, " + ", 8, _hoisted_4),
        _createElementVNode("button", {
          class: "btn btn-success btn-lg m-1 p-1",
          style: {"min-width":"2em"},
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitCustomAdd && _ctx.emitCustomAdd(...args))),
          disabled: _ctx.error
        }, " ++ ", 8, _hoisted_5)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("h4", {
          class: "ms-2",
          id: _ctx.tooltipID,
          tabindex: "0",
          "data-bs-toggle": "tooltip",
          "data-bs-title": _ctx.tooltip
        }, _toDisplayString(_ctx.item) + " ", 9, _hoisted_7)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("h2", null, [
          _createElementVNode("span", _hoisted_9, [
            _createVNode(_Transition, {
              name: "counter",
              mode: "out-in"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createElementBlock("div", { key: _ctx.value }, _toDisplayString(_ctx.value), 1))
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}