
import axios from "axios";
import { defineComponent, reactive, onMounted } from "vue";

export default defineComponent({
  name: "NewEntry",
  props: {
    reg: {
      type: Boolean,
    },
  },
  data() {
    return {
      locations: [],
      locationSelection: -1,
      name: "",
      error: false,
    };
  },
  methods: {
    registerSession() {
      axios({
        method: "post",
        url: `https://api.littertracker.greenteamscanada.ca/api/new`,
        data: {
          user: this.name.replace(/'/g, "''").trim(),
          location_id: this.locationSelection,
        },
      })
        .then((r) => {
          console.log(r);
          let d = new Date();
          d.setTime(d.getTime() + 86400000);
          document.cookie = `session=${
            r.data.data.uuid
          }; SameSite=Strict; expires=${d.toUTCString()};`;
          this.$emit("reg-session", r.data.data.uuid);
        })
        .catch((e) => {
          console.error(e);
          this.error = true;
        });
    },
    getLocations() {
      axios({
        method: "get",
        url: `https://api.littertracker.greenteamscanada.ca/api/locations`,
      })
        .then((r) => {
          this.locations = r.data.data;
          this.locationSelection = this.locations[0].id;
        })
        .catch((e) => {
          console.error(e);
          this.error = true;
        });
    },
  },
  mounted() {
    this.getLocations();
    //if no session throw error
  },
});
