
import { defineComponent } from "vue";

export default defineComponent({
  name: "AddAmount",
  props: {
    customAmountName: {
      type: String,
    },
  },
  data() {
    return {
      customAmount: 0,
    };
  },
  methods: {
    emitClose() {
      this.$emit("custom-close");
    },
    emitSumbit() {
      this.$emit("custom-add", this.customAmount);
    },
  },
});
